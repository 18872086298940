

.main_pole{
  width: 300px;
  height: 300px;
  background-color:rgb(35, 143, 169);
  border-radius: 10px;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  padding: 15px;
 
}

.box{
  border: 1px solid black;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 5px;

}

.box_img{
width: 100%;
border: 1px solid red;

}

.line_pole{
  display: flex;
}

.container{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;  
  height: 100vh;
  background-image: url('../images/maines/back.jpg');
  background-size: cover;
  
}

.menu_quantity_mines{
    /*margin-top: 50px;*/
    margin-top: 30px;
  background-color:#090F1D;
  width: 330px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around; 
  align-items: center;
}

.div_minus:hover, .div_plus:hover {
  background-color: #294B6D; /* Цвет при наведении */
  transform: scale(1.05); /* Легкое увеличение */
}


.div_minus{
  width: 60px;
  height: 60px;
  background-color:rgb(35, 143, 169);
  border-radius: 15px;  
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;
}

.span_minus{
  margin-left: 20px;  
  font-size:40px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.span_plus{  
  margin-left: 15px;  
  font-size:40px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.div_plus{
  width: 60px;
  height: 60px;
  background-color:rgb(35, 143, 169);
  border-radius: 15px;  

  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;
}

.button_signal{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  background-color:rgb(35, 143, 169); 
  /*margin-top: 50px;*/
  margin-top: 30px;
  width: 330px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 0 6px #294B6D;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transform-origin:bottom; /* Увеличение будет происходить вниз */
}


.button_signal:hover {
  transform: scaleY(1.05); /* Увеличение высоты на 20% */
  box-shadow: 0 6px #294B6D; /* Более глубокая тень при наведении */
  background-color: #294B6D; /* Цвет при наведении */
}

.span_button_signal{
  font-size:30px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


.span_quantityMines{
  font-size:30px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


.video{
  width: 300px;
  height: 300px;
  border-radius: 30px;
  transition: opacity 0.5s ease-in-out;
}

.initial_image{
  width: 300px;
  height: 300px;
}


.menu_mines{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  font-size:200%;
  font-weight: 1000;
  position: fixed;
  bottom: 0;
  z-index: 999;
  color:rgb(35, 143, 169);
} 


.menu_rotate_mines{
  color: white;
  font-family:'EvanstonTavern-1846';  
  font-weight: 600;
}






/*modal*/

.question{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.background-color_question_mines{
  background-color: rgb(35, 143, 169);
}

.margin_question{
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}



.modal-overlay-lacky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-lacky {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

.modal-header-lacky {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button-lacky {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body-lacky {
  margin-top: 20px;
  text-align: center;
  width: 280px;  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.nameGameMines{
  font-size: 20px;
  color: rgb(35, 143, 169);
  margin-left: 100px;
}


.close_button_color_mines{
  color: rgb(35, 143, 169);
}

.caption{
  font-size: 25px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  

}

.warning-message{
  background-color: rgb(35, 143, 169);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  border: 2px solid white;
  text-align: center; /* Центрирование текста внутри */
}

.obuchenie{
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px;
 background-color: rgb(35, 143, 169);
 border-radius: 10px;
 margin-bottom: 10px;
 height: 30px;
}

.number_of_traps{
  display: flex;
  justify-content: center;

}

.traps{
  font-size: 18px;
  font-weight: 400;
  color: rgb(118, 132, 136);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  
}

.text_with_question_mines{
  font-size: 15px;
  color: white;
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.info_img_mines{
  width: 280px;
  margin-bottom: 10px;

}