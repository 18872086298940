



.lackyJetBack{
    background-image: url('../images/lacky/background2.png');
    width: 100%;
    height: 100vh;  
    display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  
}

.timestartUp{
  font-size:15px;
  font-weight: 600;
  color: white;
  margin-top: 1%;
  font-family: 'GilroyLight';
}

.timestartDown{
  font-size:15px;
  font-weight: 600;
  color: white;
  margin-top: 1%;
  font-family: 'GilroyLight';
}


.counter{
    font-size:700%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyBlackItalic';
}

.timeText{
    font-size:200%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyLight';
}
.X{
    font-size:300%;
    font-weight: 600;
    color: white;
    margin-right: 1%;
    font-family: 'GilroyBlackItalic';
}
.next_pusk{
    border: 2px solid white;
    border-radius: 20px;
    padding: 10px;
}

@font-face {
    font-family: 'GilroyLight'; 
    src:url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


.preTimeText{
    font-size:140%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyLight';
}

.menu_jet{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    font-size:200%;
    font-weight: 1000;
    position: fixed;
    bottom: 0;
    z-index: 999;
    
} 


.menu_rotate{
    color: white;
    font-family: 'GilroyBlackItalic'; 
    font-weight: 600;
  }
  
  .progress-bar {
    margin-top: 20px;
    width: 290px;
    height: 20px;
    border: 2px solid white;
    position: relative;
    border-radius: 20px;
    padding: 5px;
  }
  
  .progress-bar-fill {
    border-radius: 20px;
    height: 70%;
    background-image: linear-gradient(to right, #fcc40a, #fc930a);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1;
    animation: fillAnimation 60s linear forwards;
    margin: 5px;
  }
  
  
  @keyframes fillAnimation {
    0% { width: 5%; }
    100% { width: 95%; }
  }


.question{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.background-color_question_lucky{
  background-color: rgb(37, 30, 70);
  height: 30px;
  border-radius: 10px;
  padding: 5px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  font-size: 15px;
  color: white;
  font-weight: 500;
}

.divWithLogoAndQuestion{
  display: flex;
  align-items: center;
}

/*
.modal-overlay {
  padding-top: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
*/


.modal-overlay-lacky {

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

}

.modal-lacky {
  margin-bottom: 50px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 80%; /* Ограничиваем высоту окна */
  
  padding-bottom: 20px;
  
}

.modal-header-lacky {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button-lacky {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body-lacky {
  margin-top: 20px;
  text-align: center;
  width: 280px;  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.nameGameLacky{
  font-size: 20px;
  color: rgb(37, 30, 70);
  margin-left: 80px;
}


.close_button_color_lacky{
  color: #fc930a;
}

.text_with_question{
  color:white;
  margin-right: 5px;
  font-family: 'GilroyBlackItalic'; 
  font-weight: 600;
}

.rules{
  font-size: 15px;
  max-height: 80vh;
  overflow-y: auto; /* Прокрутка внутри модального окна */
}

.info_img{
  width: 300px;
  margin-bottom: 10px;

}

p,h4{
  margin-bottom: 10px;
}
